import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "@emotion/styled";
import { graphql, Link } from "gatsby";
import { useQueryParam, StringParam } from "use-query-params";

import Section from "@components/Section";
import Seo from "@components/SEO";
import Layout from "@components/Layout";
import mediaqueries from "@styles/media";

const DataPage = (props) => {
  const {
    data: {
      articles: { edges },
    },
  } = props;
  const articles = edges
    .map((edge) => edge.node)
    .filter((article) => !article.secret);
  let targetSet = new Set();
  articles.forEach((article) => {
    if (article.effects) {
      article.effects_dict = [];
      article.effects.forEach((effect) => {
        targetSet.add(effect.name);
        article.effects_dict[effect.name] = effect.d;
      });
    }
  });
  const targetList = [...targetSet];

  // State
  var [sort, setSort] = useState({ key: null, direction: "desc" });
  var [targetQuery, setTargetQuery] = useQueryParam("target", StringParam);
  var [targetSelection, setTargetSelection] = useState(
    new Set(targetQuery ? targetQuery.split(",") : [])
  );
  var filterPost = () => {
    var posts = articles.filter((article) => {
      const { effects } = article;
      if (targetSelection.size === 0) {
        return true;
      } else if (effects != null) {
        for (let effect of effects) {
          if (targetSelection.has(effect.name)) {
            return true;
          }
        }
      }
      return false;
    });
    if (sort.key) {
      posts.sort(
        (a, b) =>
          ((a.effects_dict[sort.key] || 0) - (b.effects_dict[sort.key] || 0)) *
          (sort.direction === "asc" ? 1 : -1)
      );
    }
    return posts;
  };
  var [filteredPost, setFilteredPost] = useState(filterPost());

  // function
  var onTargetClick = (target) => {
    if (targetSelection.has(target)) {
      targetSelection.delete(target);
    } else {
      targetSelection.add(target);
    }
    setTargetSelection(targetSelection);
    setTargetQuery([...targetSelection].join(","));
    setFilteredPost(filterPost());
  };

  var handleSort = (target) => {
    if (sort.key === target) {
      sort.direction = sort.direction === "asc" ? "desc" : "asc";
    } else {
      sort.key = target;
      sort.direction = "desc";
    }
    setSort({ ...sort });
    setFilteredPost(filterPost());
  };

  return (
    <Layout>
      <Seo>
        <link rel="stylesheet" href="/css/font-awesome.min.css" />
      </Seo>
      <Search>
        <Section>
          <h1>データ比較</h1>
        </Section>
        <Section>
          <h2>効果</h2>
          {targetList.map((target) => (
            <Toggle
              className={targetSelection.has(target) ? "selected" : ""}
              key={target}
              onClick={() => onTargetClick(target)}
            >
              {target}
            </Toggle>
          ))}
        </Section>
        <Section>
          <h2>データ</h2>
          {filteredPost.length ? (
            <Table>
              <thead>
                <tr>
                  <th aria-label="blank"></th>
                  {targetList.map((target) =>
                    targetSelection.size === 0 ||
                    targetSelection.has(target) ? (
                      <th key={target}>
                        <button
                          type="button"
                          aria-label="sort"
                          className={sort.key === target ? sort.direction : ""}
                          onClick={() => handleSort(target)}
                        >
                          {target}
                        </button>
                      </th>
                    ) : null
                  )}
                </tr>
              </thead>
              <tbody>
                {filteredPost.map((post) => (
                  <tr key={post.cause}>
                    <th>
                      <Link to={post.slug}>{post.cause}</Link>
                    </th>
                    {targetList.map((target) =>
                      targetSelection.size === 0 ||
                      targetSelection.has(target) ? (
                        <td
                          key={post.cause + "-" + target}
                          style={{
                            backgroundColor:
                              "rgba(3, 160, 98, " +
                              (post.effects_dict[target]
                                ? post.effects_dict[target].toFixed(3)
                                : "0") +
                              ")",
                          }}
                        >
                          {post.effects_dict[target]
                            ? post.effects_dict[target].toFixed(3)
                            : "-"}
                        </td>
                      ) : null
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>該当する記事がありません</p>
          )}
        </Section>
        <Section>
          <h2>解説</h2>
          <p>数値は効果量、Cohen's dで表されています。</p>
          <p>Cohen's dとは標準偏差σいくつぶんの効果があったかを表します。</p>
          <p>論文に掲載されているデータが異なる指標の際は換算して掲載しています。</p>
        </Section>
      </Search>
    </Layout>
  );
};

DataPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default DataPage;

const Toggle = styled.button`
  outline: none;
  margin: 0 10px 10px 0;
  border-radius: 5px;
  padding: 5px;
  color: ${(p) => p.theme.colors.primary};
  border: 1px solid ${(p) => p.theme.colors.primary};
  cursor: pointer;
  &.selected {
    color: ${(p) => p.theme.colors.background};
    background: ${(p) => p.theme.colors.primary};
  }
`;

const Search = styled.div`
  margin: 100px auto 300px;
  max-width: 1200px;

  ${mediaqueries.desktop`
    max-width: 749px;
    margin: 100px auto 200px;
  `}

  ${mediaqueries.tablet`
    margin: 100px auto 200px;
    max-width: 480px;
  `}

  ${(p) => mediaqueries.phablet`
    margin: 100px auto 180px;
    &::before {
      content: "";
      width: 100%;
      height: 19px;
      background: ${p.theme.colors.primary};
      position: absolute;
      left: 0;
      top: 0;
      transition: ${p.theme.colorModeTransition};
    }

    &::after {
      content: "";
      width: 100%;
      height: 10px;
      background: ${p.theme.colors.background};
      position: absolute;
      left: 0;
      top: 10px;
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      transition: ${p.theme.colorModeTransition};
    }
  `}

  h1 {
    color: ${(p) => p.theme.colors.primary};
    font-size: 32px;
  }

  h2 {
    color: ${(p) => p.theme.colors.primary};
    font-size: 22px;
    margin: 20px 0 10px;
  }

  a {
    color: ${(p) => p.theme.colors.primary};
    transition: all 0.2s;
    &:hover {
      color: ${(p) => p.theme.colors.accent};
    }
  }
`;

const Table = styled.table`
  th {
    padding: 10px;
    text-align: left;
    word-break: keep-all;
  }

  td {
    padding: 10px;
    text-align: right;
  }

  button {
    border: none;
    box-shadow: none;
    line-height: 2rem;
    // height: 2rem;
    width: 100%;
    padding: 0 8px;
    text-align: left;
    letter-spacing: 0;
    position: relative;

    &:before,
    &:after {
      position: absolute;
      bottom: 0px;
      right: 0px;
      display: block;
      opacity: 0.3;
      font-family: "FontAwesome";
      font-weight: 900;
      font-size: 1rem;
    }
    &:before {
      content: "\\f0de";
    }
    &:after {
      content: "\\f0dd";
    }
    &.asc:before {
      opacity: 1;
    }
    &.desc:after {
      opacity: 1;
    }
  }
`;

export const query = graphql`
  query DataQuery {
    articles: allArticle(
      filter: { data: { eq: true } }
      sort: { fields: [date, title], order: DESC }
      limit: 1000
    ) {
      edges {
        node {
          id
          slug
          secret
          title
          author
          date(formatString: "YYYY/MM/DD")
          update(formatString: "YYYY/MM/DD")
          dateForSEO: date
          updateForSEO: update
          timeToRead
          canonical_url
          subscription
          category
          tags
          data
          cause
          effects {
            name
            d
            p
          }
        }
      }
    }
  }
`;
